import { Store } from "@reduxjs/toolkit";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { isoWebPageURL } from "../../models/nominals";
import type { Loaders } from "../common/loaders";
import { IRootState } from "../reducers.interfaces";

type IRootCommonState = Pick<IRootState, "common">;

export const initCSRToolbar = async <T extends Store<IRootCommonState>>({
    rehydratingStore,
    loaders,
    store,
}: {
    rehydratingStore: Promise<T>;
    loaders: Loaders;
    store: T;
}): Promise<void> => {
    // Wait for Redux store to finish loading
    await rehydratingStore;

    // Load the current user. If they're not a CSR, abort without doing anything more.
    const user = await loaders.loadCurrentUser();
    if (!user || !user.is_csr) {
        return;
    }

    // Load the toolbar component
    const { Toolbar } = await import("./components/Toolbar");

    // Create container elements
    const csrToolbarContainer = document.createElement("div");
    csrToolbarContainer.id = "oscarcsr-toolbar-reset";
    document.body.prepend(csrToolbarContainer);

    const csrToolbar = document.createElement("div");
    csrToolbar.id = "oscarcsr-toolbar";
    csrToolbarContainer.appendChild(csrToolbar);

    // Render the toolbar
    createRoot(csrToolbar).render(
        <Provider store={store}>
            <Toolbar
                dashboard={isoWebPageURL.wrap("/store/dashboard/")}
                basket={isoWebPageURL.wrap("/store/basket/")}
            />
        </Provider>,
    );
};
