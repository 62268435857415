import React from "react";
import SVG from "react-inlinesvg";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { jt, t } from "ttag";

import { templates } from "@reactivated";

import sealyLogo from "../src/svg/sealy-logo.svg";
import sealyStearnsLogo from "../src/svg/sealy-stearns-logo.svg";
import stearnsLogo from "../src/svg/stearns-logo.svg";
import tempurpedicLogo from "../src/svg/tempur-logo-text.svg";

type BrandingConfig = {
    title: string;
    logo: string;
    logoClassname: string;
};

const getBranding = (appSlug: string): BrandingConfig => {
    switch (appSlug) {
        case "stearnswarranty": {
            return {
                title: t`Stearns & Foster`,
                logo: stearnsLogo,
                logoClassname:
                    "site-nav__logo site-nav__logo--checkout-stearns",
            };
        }
        case "sealywarranty": {
            return {
                title: t`Sealy`,
                logo: sealyLogo,
                logoClassname: "site-nav__logo site-nav__logo--checkout-sealy",
            };
        }
        case "sealyemployees":
        case "sealyretail": {
            return {
                title: t`Sealy | Stearns & Foster Home`,
                logo: sealyStearnsLogo,
                logoClassname: "site-nav__logo site-nav__logo--checkout-alt",
            };
        }
        default: {
            return {
                title: t`Tempur-Pedic Home`,
                logo: tempurpedicLogo,
                logoClassname: "site-nav__logo site-nav__logo--checkout",
            };
        }
    }
};

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    const secure = (
        <span
            key="secure"
            className="checkout-header__header--bold"
        >{t`secure`}</span>
    );
    const secureCheckout = jt`${secure} checkout`;
    const branding = getBranding(props.app_slug);
    return (
        <header className="l-full-width">
            <nav className="site-nav checkout-header">
                <div className="site-nav__content checkout-header__content l-capped-width">
                    <a
                        className="site-nav__logo-link"
                        href={props.root_page_url}
                        title={branding.title}
                    >
                        <SVG
                            className={branding.logoClassname}
                            src={branding.logo}
                            title={branding.title}
                        />
                    </a>

                    <h1 className="checkout-header__header">
                        {secureCheckout}
                    </h1>

                    <div className="checkout-header__header-cart-ref">
                        <CartReferenceNumber
                            encodedBasketID={props.encoded_basket_id}
                            phoneNumber={props.support_website_phone_number}
                        />
                    </div>
                </div>
            </nav>
        </header>
    );
};
