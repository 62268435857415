import React from "react";
import SVG from "react-inlinesvg";

import fortivaLogo from "../../../../img/finance/fortiva-logo.svg";
import { strings } from "../../../localization";
import { IAPR, IMonths } from "../../../models/nominals";
import { formatAPR, formatTermLength } from "../../../utils/format";

import styles from "./index.module.scss";

export const FinancingFortivaOffer = (props: {
    creditLimit: string;
    apr: IAPR;
    fullDuration: IMonths | null;
}) => {
    return (
        <div className={styles.terms}>
            <SVG className={styles.logo} src={fortivaLogo} />
            <h3
                className={styles.subheading}
                dangerouslySetInnerHTML={{
                    __html:
                        strings.get("FINANCING_FORTIVA_OFFER_INTRO_TEXT") || "",
                }}
            ></h3>
            <p>
                Please review your offer details from Fortiva<sup>®</sup>{" "}
                Retail Credit below:
            </p>
            <div className={styles.details}>
                <p className={styles.detailItem}>
                    Pre-qualified Amount:
                    <strong>${props.creditLimit}</strong>
                </p>
                <p className={styles.detailItem}>
                    Annual Percentage Rate:
                    <strong>{formatAPR(props.apr)}</strong>
                </p>
                {props.fullDuration && (
                    <p className={styles.detailItem}>
                        Estimated Payoff:
                        <strong>
                            {formatTermLength(props.fullDuration)} Months*
                        </strong>
                    </p>
                )}
            </div>
            <p className={styles.fineprint}>
                <strong>*Estimated Payoff Disclosure:</strong>
                <br />
                Estimated Payoff shows how long it will take to pay off your
                purchase if you make your minimum payments by the due date each
                month.
            </p>
        </div>
    );
};
