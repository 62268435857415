import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { Form } from "../../../forms/Form";
import { FormCheckbox } from "../../../forms/FormCheckbox";
import { FormSubmit } from "../../../forms/FormSubmit";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { strings } from "../../../localization";
import { IBasket } from "../../../models/catalogue.interfaces";
import { IBasketLineAPIURL } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { BasketLineMinimal } from "../components/BasketLineMinimal";
import { ExpeditedShippingDisclosure } from "../components/ExpeditedShippingDisclosure";
import { PredictedDeliveryHelpText } from "../components/PredictedDeliveryHelpText";
import { PreferredDeliveryDate } from "../components/PreferredDeliveryDate";
import { ShippingNotes } from "../components/ShippingNotes";
import {
    PredictedDeliveryDateDisplayType,
    PreferredDeliveryDateDisplayType,
    SHIPPING_METHOD_NAMES,
    Step,
} from "../constants";
import { Dispatchers } from "../dispatchers";
import { IReduxFormState } from "../reducers.interfaces";
import {
    ILineOptionGroupingData,
    IMethodDetails,
    getLineOptionGroupingData,
} from "../selectors";
import { CheckoutStepContainer } from "./CheckoutStepContainer";
import { ShippingMethodSummary } from "./ShippingMethodSummary";

import styles from "./ShippingMethod.module.scss";

interface IOwnProps {
    heading: string;
    errors: string[];
    predictedDeliveryDates: PredictedDeliveryDateDisplayType;
    preferredDeliveryDates: PreferredDeliveryDateDisplayType;
    isCurrentStep: boolean;
    saveShippingMethod: () => Promise<IBasket>;
    savePreferredDeliveryDate: (date: Date | null) => Promise<void>;
    onContinue: (event?: React.MouseEvent<HTMLElement>) => void;
    onEdit: (event?: React.MouseEvent<HTMLElement>) => void;
    preferredDeliveryContent?: string | React.ReactNode;
    formRef?: React.RefObject<Form>;
}

interface IReduxProps {
    form: IReduxFormState;
    basket: IBasket | null;
    showForm: boolean;
    groups: ILineOptionGroupingData[];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isSaving: boolean;
}

class ShippingMethodComponent extends React.Component<IProps, IState> {
    public state: IState = {
        isSaving: false,
    };

    private async saveShippingMethod() {
        this.setState({
            isSaving: true,
        });
        await this.props.saveShippingMethod();
        this.setState({
            isSaving: false,
        });
        this.props.onContinue();
    }

    private buildLine(lineURL: IBasketLineAPIURL) {
        if (!this.props.basket) {
            return null;
        }
        const line = this.props.basket.lines.find((l) => {
            return l.url === lineURL;
        });
        if (!line || !line.quantity) {
            return null;
        }
        return <BasketLineMinimal key={`${lineURL}`} line={line} />;
    }

    private buildOptionSelector(groupIndex: number, methods: IMethodDetails[]) {
        return methods.map((method) => {
            const isChecked = this.props.form.shipping_method === method.code;
            const isWhiteGlove = method.type === "freight";
            const setShippingMethod = () => {
                this.props.dispatchers.changeFormField({
                    shipping_method: method.code,
                });
            };

            return (
                <React.Fragment key={method.code}>
                    {/*
                        Render a FormCheckbox without helpText
                        for White Glove Delivery
                    */}
                    {isWhiteGlove ? (
                        <>
                            <FormCheckbox
                                type="radio"
                                id={`shipping_method_${groupIndex}`}
                                name={`shipping_method_${groupIndex}`}
                                value={method.code}
                                disabled={methods.length === 1}
                                checked={methods.length === 1 || isChecked}
                                label={
                                    (SHIPPING_METHOD_NAMES[method.name] ||
                                        method.name) +
                                    " - " +
                                    method.price
                                }
                                className={styles.radio}
                                helpTextClass={`form__help-text--shipping-method-${groupIndex}`}
                                onChange={setShippingMethod}
                            />
                            {this.props.preferredDeliveryDates ===
                            PreferredDeliveryDateDisplayType.DISABLED ? (
                                <PredictedDeliveryHelpText
                                    type={this.props.predictedDeliveryDates}
                                    additionalHelpText={method.description}
                                />
                            ) : (
                                <PreferredDeliveryDate
                                    type={this.props.preferredDeliveryDates}
                                    savePreferredDeliveryDate={
                                        this.props.savePreferredDeliveryDate
                                    }
                                    fallbackDescription={method.description}
                                    preferredDeliveryContent={
                                        this.props.preferredDeliveryContent
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <FormCheckbox
                            type="radio"
                            id={`shipping_method_${groupIndex}`}
                            name={`shipping_method_${groupIndex}`}
                            value={method.code}
                            disabled={methods.length === 1}
                            checked={methods.length === 1 || isChecked}
                            label={
                                (SHIPPING_METHOD_NAMES[method.name] ||
                                    method.name) +
                                " - " +
                                method.price
                            }
                            className={styles.radio}
                            helpText={method.description}
                            helpTextClass={`form__help-text--shipping-method-${groupIndex}`}
                            onChange={setShippingMethod}
                        />
                    )}
                </React.Fragment>
            );
        });
    }

    private buildLineOptionGroupings() {
        if (!this.props.showForm) {
            return null;
        }
        return this.props.groups.map((group, i) => {
            const lines = group.lines.map((line) => {
                return this.buildLine(line);
            });
            const options = this.buildOptionSelector(i, group.methods);
            return (
                <div key={i} className="checkout-step__shipping-group">
                    <h3 className="checkout-step__subheading">{t`ITEM(S):`}</h3>
                    <ul>{lines}</ul>
                    <h3 className="checkout-step__subheading">
                        {t`SHIPPING METHOD:`}
                    </h3>
                    <div>
                        <fieldset className={styles.methods}>
                            <legend className="ada-screenreader-only">
                                {t`Choose a shipping method`}
                            </legend>
                            {options}
                        </fieldset>
                    </div>
                </div>
            );
        });
    }

    render() {
        const bodyClasses = classNames({
            "checkout-step__body": true,
            "checkout-step__body--collapsed": !this.props.showForm,
        });
        const onSubmit = (
            event: React.FormEvent<HTMLFormElement>,
        ): Promise<void> => {
            event.preventDefault();
            return this.saveShippingMethod();
        };
        let bodyContent: JSX.Element;
        if (this.props.groups.length > 0) {
            bodyContent = (
                <div className={bodyClasses}>
                    <Form onSubmit={onSubmit} ref={this.props.formRef}>
                        <NonFieldErrors
                            errors={this.props.errors}
                            showErrorMessages={true}
                        />
                        {this.buildLineOptionGroupings()}
                        <ExpeditedShippingDisclosure
                            groups={this.props.groups}
                        />
                        <ShippingNotes
                            value={this.props.form.shipping_notes}
                            changeFormField={
                                this.props.dispatchers.changeFormField
                            }
                            disabled={this.state.isSaving}
                        />
                        <FormSubmit
                            className="button button--full-width button--continue-to-billing"
                            value={
                                strings.get(
                                    "CONTINUE_BTN_LABEL_SHIPPING_METHOD",
                                ) || "Continue"
                            }
                            disabled={this.state.isSaving}
                        />
                    </Form>
                </div>
            );
        } else {
            bodyContent = (
                <div className={bodyClasses}>
                    <LoadingSpinner />
                </div>
            );
        }
        return (
            <CheckoutStepContainer
                className="checkout-step--shipping-method form"
                heading={this.props.heading}
                isCurrentStep={this.props.isCurrentStep}
            >
                {bodyContent}
                <ShippingMethodSummary onEdit={this.props.onEdit} />
            </CheckoutStepContainer>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        form: state.form,
        basket: state.data.basket,
        groups: getLineOptionGroupingData(state),
        showForm: state.form.current_step === Step.SHIPPING_METHOD,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ShippingMethod = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShippingMethodComponent);
