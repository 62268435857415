import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { Link } from "../../../common/Link";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { config } from "../../../config";
import { strings } from "../../../localization";
import { IProductSKU } from "../../../models/nominals";
import {
    trackBasketEvent,
    trackProductValuePageView,
} from "../../../utils/analytics";
import { urls } from "../../../utils/urls";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { BasketHeader } from "../components/BasketHeader";
import { TGetProductUpsell } from "../components/BasketLineFull";
import { BasketLines } from "../components/BasketLines";
import { BasketSummary } from "../components/BasketSummary";
import { BasketVoucherForm } from "../components/BasketVoucherForm";
import { WishListLines } from "../components/WishListLines";
import {
    BasketLineVariant,
    LIST_FAVORITES,
    LIST_PREVIOUSLY_IN_BASKET,
    PredictedDeliveryDateDisplayType,
    PreferredDeliveryDateDisplayType,
} from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { IReduxAPIData, IReduxState } from "../reducers.interfaces";

import styles from "./Basket.module.scss";

interface IOwnProps {
    getLineProductUpsell: TGetProductUpsell;
    disableFreeShippingMessage?: boolean;
    showValueProps?: boolean;
    predictedDeliveryDates?: PredictedDeliveryDateDisplayType;
    preferredDeliveryDates?: PreferredDeliveryDateDisplayType;
    getExtraSummaryContent: () => JSX.Element | null;
    getExtraSidebarContent: (
        data: IReduxAPIData,
        isMobile: boolean,
    ) => JSX.Element | null;
    getExtraMainColumnContent: (
        data: IReduxAPIData,
        isMobile: boolean,
    ) => JSX.Element | null;
    getNavHeight?: number;
    deliveryIsFree?: boolean;
}

interface IReduxProps extends IReduxState {}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isStickyActive: boolean;
    isKeyDown: boolean;
    isTooltipOpen: boolean;
}

class BasketComponent extends React.Component<IProps, IState> {
    public TABLET_WIDTH_THRESHOLD = 769;
    public MOBILE_WIDTH_THRESHOLD = 501;

    public state: IState = {
        isStickyActive: true,
        isKeyDown: false,
        isTooltipOpen: false,
    };

    constructor(props: IProps) {
        super(props);
        // When the page unloads, acknowledge having viewed any merged carts, thereby preventing them from displaying
        // on the next visit to the basket
        window.onbeforeunload = () => {
            if (this.props.data.basket) {
                this.props.data.basket.merged_from.forEach((mergedBasketID) => {
                    this.props.dispatchers.acknowledgeMergedBasket(
                        mergedBasketID,
                    );
                });
            }
        };
    }

    private readonly trackPageView = () => {
        if (this.props.data.basket) {
            const skus = this.props.data.basket.lines.reduce<IProductSKU[]>(
                (skuList, line) => skuList.concat(line.product.skus),
                [],
            );
            const price = this.props.data.basket.total_excl_tax;
            trackProductValuePageView(skus, price, "cart", "CartPageView");
            trackBasketEvent("view_cart", this.props.data.basket);
        }
    };

    async componentDidMount() {
        // Compare the localStorage cached basket to the last-modified timestamp of the
        // server's basket and see if we need to refresh our cache. If we do, do it.
        const { isOutdated } = await this.props.loaders.loadBasketIfOutdated(
            this.props.data.basket,
        );
        this.props.loaders.loadFavorites();

        // Setup resize event handlers for sticky functionality
        this.checkStickyState();
        window.addEventListener("resize", () => {
            this.checkStickyState();
        });

        // Every 30 seconds, reload the basket data if it has changed on the server. This
        // occurs if a client changes something in another tab of their browser, or if
        // the basket is edited by a CSR assisting the customer.
        const checkInterval = 30000;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        setTimeout(async function r() {
            await self.props.loaders.loadBasketIfOutdated(
                self.props.data.basket,
            );
            setTimeout(r, checkInterval);
        }, checkInterval);

        // Avoid tracking page view events for an outdated basket because
        // the basket data will trigger a page view tracking call once it is updated.
        if (this.props.data.basket && !isOutdated) {
            this.trackPageView();
        }
    }

    componentDidUpdate(prevProps: IProps) {
        const tooltip =
            this.state.isKeyDown &&
            document.querySelector(".basket-lines__tooltip.tooltip");
        if (tooltip) {
            tooltip.classList.add("key-down");
        }

        // in case basket wasn't ready on mount
        if (!prevProps.data.basket && this.props.data.basket) {
            this.trackPageView();
        }

        if (
            this.props.data.basket &&
            prevProps.data.basket &&
            prevProps.data.basket.total_excl_tax !==
                this.props.data.basket.total_excl_tax
        ) {
            this.trackPageView();
        }
    }

    private isTabletOrSmallerWidth() {
        return window.innerWidth < this.TABLET_WIDTH_THRESHOLD;
    }

    private isMobileWidth() {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    }

    private checkStickyState() {
        this.setState({
            isStickyActive: !this.isTabletOrSmallerWidth(),
        });
    }

    private getItemsLabel(qty: number) {
        return qty === 1 ? "item" : "items";
    }

    private buildMultiCartMergedMessage() {
        // Filter out merged baskets that have already been acknowledged.
        const mergedBasketIDs = this.props.data.basket
            ? this.props.data.basket.merged_from
            : [];
        const unacknowledgedMergedBaskets = mergedBasketIDs.filter(
            (basketID) => {
                return (
                    this.props.derived.acknowledged_merged_baskets.indexOf(
                        basketID,
                    ) === -1
                );
            },
        );

        // If no unacknowledged merged basket IDs exist, don't display the message
        if (unacknowledgedMergedBaskets.length <= 0) {
            return null;
        }

        return (
            <div className="basket-multicart-alert">
                <div>{t`Important messages about items in your cart.`}</div>
                <div className="basket-multicart-alert__copy basket-multicart-alert__copy--bold">
                    {t`We found another cart associated with your email address.`}
                </div>
                <div>
                    {t`The carts have been merged for your convenience. Please review your cart carefully before proceeding to checkout.`}
                </div>
            </div>
        );
    }

    private buildCheckoutButton(isMobile: boolean) {
        const checkoutURL = urls.pageURL("checkout-index");
        const classes = classNames({
            "basket-summary__checkout": true,
            "basket-summary__checkout--mobile": isMobile,
            "al-basket-summary__checkout": true,
        });
        return (
            <div className={classes}>
                <Link
                    href={checkoutURL}
                    target="_top"
                    className="button button--full-width"
                    id="basket-summary__checkout--shop-tracking"
                >
                    {t`Checkout`}
                </Link>
            </div>
        );
    }

    private buildCurrentBasket(isMobile: boolean) {
        let totalItems = 0;
        if (this.props.data.basket) {
            totalItems = this.props.data.basket.lines.reduce((memo, line) => {
                return memo + line.quantity;
            }, 0);
        }
        let checkoutButton: JSX.Element | null = null;
        if (isMobile && totalItems > 0) {
            checkoutButton = this.buildCheckoutButton(isMobile);
        }
        return (
            <section>
                <BasketHeader
                    hideBasketID={!this.props.data.basket}
                    phoneLink={strings.get("WEBSITE_SUPPORT_NUMBER_LINK")}
                    phoneNumber={strings.get("WEBSITE_SUPPORT_NUMBER")}
                    basketID={this.props.data.basket?.encoded_basket_id}
                    isMobile={this.isTabletOrSmallerWidth()}
                    totalItems={totalItems}
                />
                {checkoutButton}
                <BasketLines
                    financingPlans={this.props.data.financing_plans}
                    basket={this.props.data.basket}
                    shippingMethodCode={this.props.form.shipping_method}
                    getProductUpsell={this.props.getLineProductUpsell}
                    showValueProps={!!this.props.showValueProps}
                    predictedDeliveryDates={
                        this.props.predictedDeliveryDates ||
                        PredictedDeliveryDateDisplayType.DISABLED
                    }
                    preferredDeliveryDates={
                        this.props.preferredDeliveryDates ||
                        PreferredDeliveryDateDisplayType.DISABLED
                    }
                    loadConcreteBundles={this.props.loaders.loadConcreteBundles}
                    addBasketLine={this.props.actions.addBasketLine}
                    updateBasketLineQuantity={
                        this.props.actions.updateBasketLineQuantity
                    }
                    removeBasketLine={this.props.actions.removeBasketLine}
                    variant={BasketLineVariant.FULL}
                    isMobile={this.isMobileWidth()}
                    deliveryIsFree={this.props.deliveryIsFree}
                    removeVoucherCode={this.props.actions.removeVoucherCode}
                />
            </section>
        );
    }

    private buildFavorites() {
        const lines = this.props.data.wishlists[LIST_FAVORITES] || [];
        return (
            <div className="basket-lines__block basket-lines__block--favorites">
                <div className="basket-lines__header">
                    <h1 className="basket-lines__header-title">
                        {t`Your Favorites`}
                    </h1>
                </div>
                <WishListLines
                    lines={lines}
                    removeWishlistLine={this.props.actions.removeWishlistLine}
                />
            </div>
        );
    }

    private buildProductsPreviouslyInBasket() {
        const lines = this.props.data.wishlists[LIST_PREVIOUSLY_IN_BASKET];
        if (!lines || !lines.length) {
            return null;
        }
        return (
            <div className="basket-lines__block basket-lines__block--previously-in-basket">
                <h2 className="basket-lines__header">
                    {t`Previously in Cart`}{" "}
                    <span className="basket-lines__header__count">
                        ({lines.length} {this.getItemsLabel(lines.length)})
                    </span>
                </h2>
                <WishListLines
                    lines={lines}
                    addBasketLine={this.props.actions.addBasketLine}
                    removeWishlistLine={this.props.actions.removeWishlistLine}
                />
            </div>
        );
    }

    private renderAuxBasketContent() {
        const elem = document.getElementById("aux-basket-content");
        const content = {
            __html: elem ? elem.innerHTML : "",
        };

        return (
            <div
                className="aux-basket-content"
                dangerouslySetInnerHTML={content}
            ></div>
        );
    }

    private renderContent() {
        const isMobile = this.isTabletOrSmallerWidth();
        const homeURL = urls.pageURL("home");
        let checkoutBtn: JSX.Element | null = null;
        if (this.props.data.basket && this.props.data.basket.lines.length > 0) {
            checkoutBtn = (
                <div className="basket-summary__buttons">
                    {this.buildCheckoutButton(isMobile)}
                    <div className="basket-summary__continue-shopping al-basket__continue-shopping">
                        <Link
                            href={homeURL}
                            target="_top"
                            className="button button--full-width button--inverse"
                        >
                            {t`Continue Shopping`}
                        </Link>
                    </div>
                </div>
            );
        }
        const mainContent = config.get("ENABLE_ECOM") ? (
            <>
                {this.buildMultiCartMergedMessage()}
                {this.buildCurrentBasket(isMobile)}
                {this.buildProductsPreviouslyInBasket()}
            </>
        ) : (
            <>{this.buildFavorites()}</>
        );
        const extraContent = this.props.getExtraMainColumnContent
            ? this.props.getExtraMainColumnContent(this.props.data, isMobile)
            : null;

        const extraSidebarContent = this.props.getExtraSidebarContent
            ? this.props.getExtraSidebarContent(this.props.data, isMobile)
            : null;

        return (
            <div className={`${styles.root} basket l-capped-width`}>
                <div className="basket-lines">
                    {mainContent}
                    {this.renderAuxBasketContent()}
                    <ErrorBoundary>{extraContent}</ErrorBoundary>
                </div>
                <div className="basket-summary">
                    <div className={styles.stickyContainer}>
                        {config.get("ENABLE_ECOM") && (
                            <>
                                <h2 className="basket-summary__header">
                                    {t`Order Summary`}
                                </h2>
                                <BasketSummary
                                    data={this.props.data}
                                    derived={this.props.derived}
                                    disableShipping={true}
                                    disableFreeShippingMessage={
                                        this.props.disableFreeShippingMessage
                                    }
                                    disableTaxes={true}
                                    removeVoucherCode={
                                        this.props.actions.removeVoucherCode
                                    }
                                    getExtraContent={
                                        this.props.getExtraSummaryContent
                                    }
                                />
                                <div className="basket-summary__promocode">
                                    <BasketVoucherForm
                                        addVoucherCode={
                                            this.props.actions.addVoucherCode
                                        }
                                    />
                                </div>
                                {checkoutBtn}
                            </>
                        )}
                        <ErrorBoundary>{extraSidebarContent}</ErrorBoundary>
                    </div>
                </div>
            </div>
        );
    }

    private renderLoadingSpinner() {
        return <LoadingSpinner />;
    }

    render() {
        if (!this.props.data.basket) {
            return this.renderLoadingSpinner();
        }
        return this.renderContent();
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        ...state,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const Basket = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasketComponent);
