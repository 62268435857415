import { addSeconds, formatDistanceToNow } from "date-fns";
import React, { memo, useEffect, useRef, useState } from "react";
import { t } from "ttag";

import { sendSessionKeepAlivePing } from "../../../api/user";
import { Modal, ModalStyles } from "../../../common/Modal";
import { SessionKeepAliveResponse } from "../../../models/user";

// A small throttle helper
function throttle(func: () => Promise<void>, delay: number) {
    let lastCall = 0;
    return async () => {
        const now = Date.now();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        await func();
    };
}

// Memoized modal content.
// This only re-renders if `isOpen`, `remainingSeconds`, or `onStayLoggedIn` changes.
const SessionExpiryModal = memo(function SessionExpiryModal({
    isOpen,
    remainingSeconds,
    onStayLoggedIn,
}: {
    isOpen: boolean;
    remainingSeconds: number;
    onStayLoggedIn: () => void;
}) {
    const modalStyleProps: ModalStyles = {
        content: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            width: "450px",
            height: "200px",
            padding: "20px",
            overflow: "hidden",
            boxShadow: "none",
            textAlign: "center",
        },
    };
    // If the modal isn't open, don't render anything at all.
    if (!isOpen) {
        return null;
    }
    let modalContent: JSX.Element;
    if (remainingSeconds <= 0) {
        modalContent = (
            <div>
                <p>
                    <strong>{t`Your session has expired.`}</strong>
                </p>
            </div>
        );
    } else {
        // Format a human-readable remaining time.
        const sessionEnd = addSeconds(new Date(), remainingSeconds);
        const remainingTime = formatDistanceToNow(sessionEnd, {
            includeSeconds: true,
        });
        modalContent = (
            <div>
                <p>
                    <strong>
                        {t`Your session will expire in ${remainingTime}.`}
                    </strong>
                </p>
                <p>{t`To stay logged in, please click the button below.`}</p>
                <button className="button" onClick={onStayLoggedIn}>
                    {t`Stay Logged In`}
                </button>
            </div>
        );
    }

    return (
        <Modal
            className="session-keep-alive-modal"
            contentLabel={t`User Idle Timeout`}
            style={modalStyleProps}
            isOpen={isOpen}
            onRequestClose={onStayLoggedIn}
        >
            {modalContent}
        </Modal>
    );
});

export const SessionKeepAlive = () => {
    /**
     * We keep these refs so changing them won't cause a re-render:
     * - idleTimer (the timeout ID)
     * - throttledKeepAlive (the function that pings the server on user activity)
     */
    const idleTimerRef = useRef<number | null>(null);
    const throttledKeepAliveRef = useRef<(() => Promise<void>) | null>(null);

    // Remaining session time is used in the UI, so we store it in state.
    const [remainingSessionSeconds, setRemainingSessionSeconds] =
        useState<number>(1209600);
    const intervalMS = 1000;
    const initialPingDelayMS = 15000;
    const sessionWarningThresholdSeconds = 180;

    /**
     * Set up the initial keep-alive throttle after a delay,
     * and attach UI event listeners once. Cleanup on unmount.
     */
    useEffect(() => {
        const timerId = window.setTimeout(() => {
            // Throttle by 45-75s
            const throttleTime = (Math.random() * 30 + 45) * 1000;
            throttledKeepAliveRef.current = throttle(
                doSessionKeepAlive,
                throttleTime,
            );

            // Fire an immediate keep-alive when we attach
            throttledKeepAliveRef.current();

            // Add UI event listeners
            document.addEventListener("mousemove", handleUIEvent);
            document.addEventListener("keypress", handleUIEvent);
        }, initialPingDelayMS);

        return () => {
            window.clearTimeout(timerId);
            document.removeEventListener("mousemove", handleUIEvent);
            document.removeEventListener("keypress", handleUIEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fired by user activity => call throttledKeepAlive
    const handleUIEvent = () => {
        if (throttledKeepAliveRef.current) {
            throttledKeepAliveRef.current();
        }
    };

    /**
     * Actually do the keep-alive ping.
     * This is wrapped in a lock if available.
     */
    const doSessionKeepAlive = async () => {
        const inner = async () => {
            if (idleTimerRef.current) {
                clearTimeout(idleTimerRef.current);
            }
            const resp = await sendSessionKeepAlivePing();
            if (SessionKeepAliveResponse.is(resp)) {
                setIdleTimer(
                    getCurrentTimestamp(),
                    resp.session_lifetime_seconds,
                );
            } else {
                doTokenRefresh(resp.refresh_url);
            }
        };
        try {
            if (navigator.locks) {
                await navigator.locks.request("session-keep-alive", inner);
            } else {
                await inner();
            }
        } catch (e) {
            console.warn(e);
        }
    };

    /**
     * Sets (or resets) the idle timer that decrements the session.
     * Also reloads if we go too far past expiration.
     */
    const setIdleTimer = (
        fetchedTimestamp: number,
        sessionLifetime: number,
    ) => {
        const now = getCurrentTimestamp();
        // Fuzz the remaining time a little
        const fuzzTime = Math.random() * 60;
        const remainingTime =
            sessionLifetime - (now - fetchedTimestamp) - fuzzTime;
        // Clear existing timer if needed
        if (idleTimerRef.current) {
            clearTimeout(idleTimerRef.current);
        }
        // 5 seconds after session expires, reload
        if (remainingTime <= -5) {
            window.location.reload();
            return;
        }

        // Update state (triggers a re-render => updates modal)
        setRemainingSessionSeconds(remainingTime);

        // Set new timer to update again in `intervalMS`.
        idleTimerRef.current = window.setTimeout(() => {
            setIdleTimer(fetchedTimestamp, sessionLifetime);
        }, intervalMS);
    };

    const getCurrentTimestamp = () => {
        return Date.now() / 1000;
    };

    const doTokenRefresh = (refreshURL: string) => {
        const width = 320;
        const height = 240;
        const options = [
            ["toolbar", "no"],
            ["location", "no"],
            ["directories", "no"],
            ["status", "no"],
            ["menubar", "no"],
            ["scrollbars", "no"],
            ["resizable", "yes"],
            ["width", width],
            ["height", height],
            ["top", screen.height / 2 - height / 2],
            ["left", screen.width / 2 - width / 2],
        ];
        const encodedOptions = options.map((pair) => pair.join("=")).join(", ");
        const popup = window.open(refreshURL, "Token Refresh", encodedOptions);
        if (!popup) {
            alert("Please enable popups for this website.");
        }
    };

    const modalIsOpen =
        remainingSessionSeconds < sessionWarningThresholdSeconds;

    return (
        <SessionExpiryModal
            isOpen={modalIsOpen}
            remainingSeconds={remainingSessionSeconds}
            onStayLoggedIn={doSessionKeepAlive}
        />
    );
};
