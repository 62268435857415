import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React, { useState } from "react";

import { RichNavigationBannerContentAssembly, templates } from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { BannerBlock } from "../../../blocks/BannerBlock";
import { useCurrentBreakpoint } from "../../../utils/hooks";
import { RichNavigationLink } from "../RichNavigationLink";
import { RichNavigationLinkCollection } from "../RichNavigationLinkCollection";

import styles from "./index.module.scss";

export type LinkCollection = { id: number; isOpen: boolean };

export const bannerContentAssemblyComponents: StreamFieldBlockMap<RichNavigationBannerContentAssembly> =
    {
        banner: BannerBlock,
    };

interface IRichNavigationProps {
    openMobileContentDefaultState?: boolean;
}

// Create a union type of interface & the template props
type RichNavigationProps = IRichNavigationProps &
    templates.RichNavigationTemplate["rich_navigation"];

export const RichNavigation = (props: RichNavigationProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const viewport = useCurrentBreakpoint();
    // Create an Array for open/close states of link collections on mobile
    // Use openMobileContentDefaultState prop to set the initial state of the menus
    // Otherwise initially open the first collection
    const linkCollections: LinkCollection[] = props?.content
        ? props.content.value.map((_, i: number) => ({
              id: i,
              isOpen: props.openMobileContentDefaultState ?? i === 0,
          }))
        : [];
    const [openMobileContent, setOpenMobileContent] =
        useState<LinkCollection[]>(linkCollections);
    if (!props) {
        return null;
    }
    return (
        <NavigationMenu.Root
            className={styles.root}
            onValueChange={(val: string) =>
                val ? setIsOpen(true) : setIsOpen(false)
            }
        >
            <NavigationMenu.List
                data-orientation={`${viewport.belowMobile ? "vertical" : "horizontal"}`}
                className={styles.list}
            >
                {props.content &&
                    props.content.value.map((block, i: number) => {
                        switch (block.type) {
                            case "rich_nav_link_collection":
                                return (
                                    <NavigationMenu.Item key={i}>
                                        <RichNavigationLinkCollection
                                            collection={block}
                                            banner_content={
                                                props.banner_content &&
                                                !viewport.belowMobile
                                                    ? props.banner_content.value
                                                    : null
                                            }
                                            value_props={props.value_props}
                                            openMobileContent={
                                                openMobileContent
                                            }
                                            setOpenMobileContent={
                                                setOpenMobileContent
                                            }
                                            mobileContentIndex={i}
                                        />
                                    </NavigationMenu.Item>
                                );
                            case "link":
                                return (
                                    <NavigationMenu.Item key={i}>
                                        <RichNavigationLink {...block.value} />
                                    </NavigationMenu.Item>
                                );
                            default:
                                return <></>;
                        }
                    })}
            </NavigationMenu.List>
            {props.banner_content && viewport.belowMobile && (
                <StreamField
                    components={bannerContentAssemblyComponents}
                    value={props.banner_content.value}
                />
            )}
            {isOpen && <div className={styles.overlay}></div>}
        </NavigationMenu.Root>
    );
};
