import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";

import {
    RichNavigationBannerContentAssembly,
    RichNavigationLinkCollection as RichNavigationLinkCollectionValue,
    RichNavigationLinkTypes,
} from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import {
    classNames,
    concatClassNames,
} from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { RichText } from "../../../common/RichText";
import { ISafeHTML } from "../../../models/nominals";
import { useCurrentBreakpoint } from "../../../utils/hooks";
import {
    LinkCollection,
    bannerContentAssemblyComponents,
} from "../RichNavigation";
import { RichNavigationBannerLink } from "../RichNavigationBannerLink";
import { RichNavigationButtonLink } from "../RichNavigationButtonLink";
import { RichNavigationDynamicProductLink } from "../RichNavigationDynamicProductLink";
import { RichNavigationGeneralLink } from "../RichNavigationGeneralLink";
import { RichNavigationImageLink } from "../RichNavigationImageLink";
import { RichNavigationOfferLink } from "../RichNavigationOfferLink";
import { RichNavigationProductLink } from "../RichNavigationProductLink";
import { RichNavigationImagePromo } from "../RichNavigationPromoFeatures";
import { RichNavigationVariantOptions } from "../constants";

import sharedStyles from "../shared.module.scss";
import styles from "./index.module.scss";

const richNavigationLinkTypeComponents: StreamFieldBlockMap<RichNavigationLinkTypes> =
    {
        rich_nav_general_link: RichNavigationGeneralLink,
        rich_nav_offer_link: RichNavigationOfferLink,
        rich_nav_product_link: RichNavigationProductLink,
        rich_nav_dynamic_product_link: RichNavigationDynamicProductLink,
        rich_nav_image_link: RichNavigationImageLink,
        rich_nav_banner_link: RichNavigationBannerLink,
        rich_nav_button_link: RichNavigationButtonLink,
    };

interface ICollectionProps {
    value: RichNavigationLinkCollectionValue;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export interface RichNavigationLinkCollectionContentProps
    extends ICollectionProps {
    isCurrentCollectionOpen: boolean;
    mobileContentIndex?: number;
}

export interface WrapperProps {
    collection: ICollectionProps;
    banner_content: RichNavigationBannerContentAssembly | null;
    value_props?: {
        value_prop: {
            title: {
                html: string | ISafeHTML;
            };
            icon: {
                block: IIconBlock;
            } | null;
        };
        link: {
            url: string;
        } | null;
    }[];
    openMobileContent: LinkCollection[];
    setOpenMobileContent: React.Dispatch<
        React.SetStateAction<LinkCollection[]>
    >;
    mobileContentIndex: number;
}

interface IMenuTrigger {
    children: React.ReactNode;
    setOpenMobileContent: React.Dispatch<
        React.SetStateAction<LinkCollection[]>
    >;
    mobileContentIndex: number;
}

interface IIconBlock {
    title: string;
    width: number;
    height: number;
    brightness: number;
    url: string;
    renditions: {
        [k: string]: IRenditionsProp;
    };
}

interface IRenditionsProp {
    spec: string;
    width: number;
    height: number;
    url: string;
    size: number;
    mimetype: string;
}

interface IValuePropItem {
    iconUrl: string | undefined;
    titleHtml: string | ISafeHTML;
    linkUrl: string | undefined;
}

interface IValueProps {
    value_props: WrapperProps["value_props"];
    enableValuePropsOnMobile?: boolean;
}

export const ValuePropItem = ({
    iconUrl,
    titleHtml,
    linkUrl,
}: IValuePropItem) => {
    const content = (
        <>
            {iconUrl && <img src={iconUrl} alt="" />}
            {titleHtml && <RichText inline html={titleHtml} />}
        </>
    );

    return linkUrl ? <a href={linkUrl}>{content}</a> : content;
};

export const ValueProps = (props: IValueProps) => {
    const viewport = useCurrentBreakpoint();
    // Value check
    if (!props.value_props || props.value_props.length <= 0) {
        return null;
    }
    // Property based check
    if (!props.enableValuePropsOnMobile && viewport.belowMobile) {
        return null;
    }
    return (
        <ul className={styles.valueProps}>
            {props.value_props.map((item, i) => (
                <li key={i}>
                    <ValuePropItem
                        iconUrl={item.value_prop.icon?.block.url}
                        titleHtml={item.value_prop.title?.html}
                        linkUrl={item.link?.url}
                    />
                </li>
            ))}
        </ul>
    );
};

const MenuTrigger = (props: IMenuTrigger) => {
    const viewport = useCurrentBreakpoint();

    const onToggleAccordion = () => {
        props.setOpenMobileContent((prev) => {
            return prev.map((accordion) => {
                // If it's the clicked accordion, toggle it
                if (accordion.id === props.mobileContentIndex) {
                    return { ...accordion, isOpen: !accordion.isOpen };
                }
                // Close all other accordions
                return { ...accordion, isOpen: false };
            });
        });
    };

    if (viewport.belowMobile) {
        return <div onClick={onToggleAccordion}>{props.children}</div>;
    }
    return (
        <NavigationMenu.Trigger
            className={concatClassNames([
                sharedStyles.hoverEffect,
                styles.trigger,
            ])}
        >
            {props.children}
        </NavigationMenu.Trigger>
    );
};

const MenuContent = (props: {
    children: React.ReactNode;
    hasBannerContent: boolean;
}) => {
    const viewport = useCurrentBreakpoint();
    if (viewport.belowMobile) {
        return <>{props.children}</>;
    }
    return (
        <NavigationMenu.Content
            className={classNames([
                [styles.content, true],
                [styles.hasBannerContent, props.hasBannerContent],
            ])}
        >
            {props.children}
        </NavigationMenu.Content>
    );
};

export const RichNavigationLinkCollection = (props: WrapperProps) => {
    const isCurrentCollectionOpen =
        props.openMobileContent[props.mobileContentIndex].isOpen;
    const linkClasses = classNames([
        [styles.link, true],
        [props.collection.value.collection_name_style?.style, true],
        [styles.linkMobileOpen, isCurrentCollectionOpen],
    ]);
    return (
        <section
            className={concatClassNames([
                "rich-nav-link-collection",
                props.collection.value.variant ??
                    RichNavigationVariantOptions.DEFAULT,
            ])}
        >
            <MenuTrigger
                setOpenMobileContent={props.setOpenMobileContent}
                mobileContentIndex={props.mobileContentIndex}
            >
                <h2
                    className={linkClasses}
                    id="rich-navigation-collection-label"
                    aria-expanded={isCurrentCollectionOpen}
                    aria-controls="rich-navigation-collection-menu"
                >
                    {props.collection.value.name}
                </h2>
            </MenuTrigger>
            <MenuContent
                hasBannerContent={
                    props.banner_content
                        ? props.banner_content.length > 0
                        : false
                }
            >
                <RichNavigationLinkCollectionContent
                    {...props.collection}
                    isCurrentCollectionOpen={isCurrentCollectionOpen}
                />
                <ValueProps value_props={props.value_props} />
                {props.banner_content && (
                    <div className={styles.bannerWrapper}>
                        <StreamField
                            tagName={React.Fragment}
                            components={bannerContentAssemblyComponents}
                            value={props.banner_content}
                        />
                    </div>
                )}
            </MenuContent>
        </section>
    );
};

export const RichNavigationLinkCollectionContent = (
    props: RichNavigationLinkCollectionContentProps,
) => {
    const viewport = useCurrentBreakpoint();
    let wrapperClass = styles.defaultWrapper;
    if (props.value.promo_features && props.value.promo_features.length > 0) {
        props.value.promo_features.map((feature) => {
            if (
                !feature.value.promo_period ||
                feature.value.promo_period.is_promo_active
            ) {
                wrapperClass = styles.promoFeatureWrapper;
            }
        });
    }

    const Content = () => {
        if (!props.value.links) {
            return null;
        }
        return (
            <div
                className={wrapperClass}
                id="rich-navigation-collection-menu"
                aria-labelledby="rich-navigation-collection-label"
                aria-hidden={!props.isCurrentCollectionOpen}
            >
                <div className={styles.linkWrapper}>
                    {props.value.links.map((link, i) => (
                        <StreamField
                            key={i}
                            tagName={React.Fragment}
                            components={richNavigationLinkTypeComponents}
                            value={link.value}
                        />
                    ))}
                </div>
                {props.value.promo_features &&
                    viewport.aboveSmall &&
                    props.value.promo_features.map((block) => {
                        switch (block.type) {
                            case "image_promo":
                                return (
                                    <RichNavigationImagePromo
                                        className={styles.imagePromo}
                                        value={block.value}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
            </div>
        );
    };
    return (
        <>
            {!viewport.belowMobile && <Content />}
            {viewport.belowMobile && props.isCurrentCollectionOpen && (
                <Content />
            )}
        </>
    );
};
