import React from "react";
import SVG from "react-inlinesvg";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { RichText } from "tsi-common-react/src/common/RichText";
import Trans from "tsi-common-react/src/common/Trans";
import { FinancingPlan } from "tsi-common-react/src/models/financing";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import iconSpeechBubblesTalk from "../../svg/speech-bubbles-talk.svg";
import { BasketFinePrint } from "./BasketFinePrint";

interface IProps {
    plans: FinancingPlan[];
    isMobile: boolean;
    cartID?: string;
}

export const BasketSidebarContent = (props: IProps) => {
    const ctaFmtString = t`<ChatLink>Chat</ChatLink> or <PhoneLink>Call</PhoneLink> and reference <BasketID></BasketID>.`;
    const msg = (
        <Trans
            fmtString={ctaFmtString}
            data={{
                ChatLink: (content) => (
                    <ChatLink
                        key="chat-link"
                        className="basket-summary__help--link"
                        chatOnlineText={content}
                        chatOfflineLink={urls.pageURL("contact-link")}
                    />
                ),
                PhoneLink: (content) => (
                    <strong key="phone-link">
                        {content}&nbsp;
                        <a
                            href={getPageSetting(
                                "support-website-phone-number-link",
                            )}
                        >
                            {getPageSetting(
                                "support-website-phone-number-display",
                            )}
                        </a>
                    </strong>
                ),
                BasketID: () => <strong key="basket-id">{props.cartID}</strong>,
            }}
        />
    );

    let questionsBlock: JSX.Element | null = null;
    if (strToBool(getPageSetting("enable-basket-sidebar-questions-block"))) {
        questionsBlock = (
            <div className="basket-summary__help">
                <h3>
                    <SVG
                        className="basket-summary__help-icon"
                        src={iconSpeechBubblesTalk}
                        title={t`Speech Bubbles Icon`}
                    />
                    {t`Questions?`}
                </h3>
                <p>{msg}</p>
            </div>
        );
    }

    let finePrint: JSX.Element | null = null;
    if (props.isMobile) {
        finePrint = <BasketFinePrint plans={props.plans} />;
    }

    return (
        <div className="basket-summary__sidebar-content">
            {questionsBlock}
            <RichText html={getPageSetting("basket-sidebar-extra-content")} />
            {finePrint}
        </div>
    );
};
