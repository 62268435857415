import * as t from "io-ts";

import {
    CharBlock,
    ChoiceBlock,
    EmbedBlock,
    ImageChooserBlock,
    ListBlock,
    ProductChooserSubBlock,
    SnippetChooserBlock,
    SpacingSubBlock,
    StreamBlock,
    StreamValue,
    StructBlock,
} from "../../models/streamfield-blocks";
import { nullable } from "../../models/utils";

const Content = StreamBlock([
    StreamValue("image", nullable(ImageChooserBlock)),
    StreamValue("video_block", EmbedBlock),
]);

const Slide = StructBlock(
    {
        content: Content,
        caption: nullable(CharBlock),
        caption_style: nullable(ChoiceBlock),
        caption_text_alignment: nullable(ChoiceBlock),
        video_thumbnail: nullable(ImageChooserBlock),
    },
    {},
);

export type ISlide = t.TypeOf<typeof Slide>;

export const SlideCollection = t.interface({
    title: t.string,
    collection: t.array(StreamValue("slide", Slide)),
});

const AttributePair = StructBlock(
    {
        code: t.string,
        value: t.string,
    },
    {},
);
export type IAttributePair = t.TypeOf<typeof AttributePair>;

const Gallery = StructBlock(
    {
        initial_variant: nullable(ProductChooserSubBlock),
        product: nullable(ProductChooserSubBlock),
        attributes: nullable(ListBlock(AttributePair)),
        gallery: SnippetChooserBlock(SlideCollection),
    },
    {},
);
export type IGallery = t.TypeOf<typeof Gallery>;

export const GalleryBlock = StructBlock(
    {
        initial_variant: nullable(ProductChooserSubBlock),
        spacing: SpacingSubBlock,
        galleries: ListBlock(Gallery),
    },
    {},
);
export type IGalleryBlock = t.TypeOf<typeof GalleryBlock>;
