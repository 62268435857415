import React from "react";
import { Provider } from "react-redux";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { store } from "../store";

dynamicPlaceComponent('[data-place-react="mattress-match-app"]', async () => {
    const { MattressMatchContainer } = await import(
        "tsi-common-react/src/apps/mattress-match/containers/MattressMatch"
    );
    return (
        <Provider store={store}>
            <MattressMatchContainer />
        </Provider>
    );
}).catch(console.error);
