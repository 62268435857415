import React, { useId } from "react";
import SVG from "react-inlinesvg";
import ReactTooltip from "react-tooltip";
import { t } from "ttag";

import iconQuestionMarkCircleFilled from "../../../../img/icons/question-mark-circle-filled.svg";
import { useCurrentBreakpoint } from "../../../utils/hooks";

export const CartReferenceNumber = (props: {
    encodedBasketID: string;
    phoneNumber: string;
    helpIcon?: string;
}): React.ReactNode => {
    const tipId = useId();
    const viewport = useCurrentBreakpoint();
    return (
        <div className="basket-header__header-cart-ref">
            <span className="basket-lines__header-cart-ref--first-line">
                {t`Cart Reference Number: `}
            </span>
            <span className="basket-lines__header-cart-ref--second-line tooltip-container">
                <span
                    className="checkout-header__header-cart-ref--bold"
                    aria-label={t`Cart Reference Number`}
                >
                    {props.encodedBasketID}
                </span>
                {/*
                Automatically activated tooltips should not use <button>s as their triggers
                so using <div> with tabindex="0" as per a360 articles
                https://hub.accessible360.com/kb/articles/524#heading-if-youre-using-automatic-activation-
                */}
                <div
                    aria-describedby={tipId}
                    aria-expanded={false}
                    className="basket-lines__header-cart-ref-icon-container"
                    data-for={tipId}
                    data-tip={true}
                    tabIndex={0}
                >
                    <SVG
                        aria-hidden="true"
                        className="basket-lines__header-cart-ref-icon"
                        src={props.helpIcon || iconQuestionMarkCircleFilled}
                        title={t`Questions Icon`}
                    />
                    <span className="ada-screenreader-only">
                        {t`Cart Reference Number Explanation`}
                    </span>
                </div>
                <ReactTooltip
                    aria-label={t`Question about your order?`}
                    class="basket-lines__tooltip tooltip"
                    event={"mouseover focus"}
                    eventOff="scroll mousewheel mouseleave mouseout blur"
                    id={tipId}
                    place={viewport.belowLaptop ? "left" : "bottom"}
                    role="tooltip"
                    isCapture={true}
                >
                    <strong>{t`Have an issue completing your order?`}</strong>
                    <br />
                    {t`Call ${props.phoneNumber} and provide your cart ID for assistance.`}
                </ReactTooltip>
            </span>
        </div>
    );
};
