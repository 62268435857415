import classNames from "classnames";
import React, { useRef } from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import iconArrowLeftBlue from "../../../../img/icons/arrow_left_blue.svg";
import iconArrowRightBlue from "../../../../img/icons/arrow_right_blue.svg";
import iconCarouselArrowLeft from "../../../../img/icons/carousel-arrow-left.svg";
import iconCarouselArrowRight from "../../../../img/icons/carousel-arrow-right.svg";
import { Image } from "../../../common/Image";
import { RawHTML } from "../../../common/RawHTML";
import { IImageURL, isoImageURL } from "../../../models/nominals";
import { BreakPoint, IViewport } from "../../../models/screen.interfaces";
import { getPageSetting } from "../../../utils/settings";
import { IGallery, ISlide } from "../models";

import styles from "./gallery.module.scss";

interface IProps {
    gallery: IGallery;
    defaultThumbnail: IImageURL;
    viewport: IViewport;
}

enum GalleryCaption {
    SEALY_BLUE = "gallery-caption--sealy-blue",
    SEALY_YELLOW = "gallery-caption--sealy-yellow",
    SEALY_LIME_YELLOW = "gallery-caption--sealy-lime-yellow",
    SEALY_LIGHT_GREEN = "gallery-caption--sealy-light-green",
    SEALY_ALIZARIN_CRIMSON = "gallery-caption--sealy-alizarin-crimson",
    ORIENT = "gallery-caption--orient",
    RAZZMATAZZ = "gallery-caption--razzmatazz",
    YELLOW = "gallery-caption--yellow",
    TEAL = "gallery-caption--teal",
}

enum GalleryTextAlignment {
    CENTER = "gallery-caption--text-center",
    RIGHT = "gallery-caption--text-right",
}

export const GalleryPlaceHolder = (props: IProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    const isMediumScreenWidth = props.viewport.width <= BreakPoint.MEDIUM;
    const isSmallScreenWidth =
        Math.min(props.viewport.width, document.documentElement.clientWidth) <=
        BreakPoint.SMALL;
    const currentSlide = props.gallery.gallery.collection[0];

    const renderSlide = (slide: ISlide) => {
        const captionClasses = classNames({
            [styles.galleryCaption]: !slide.caption_style,
            [styles.galleryCaptionSealyBlue]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_BLUE,
            [styles.galleryCaptionSealyYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_YELLOW,
            [styles.galleryCaptionSealyLimeYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_LIME_YELLOW,
            [styles.galleryCaptionSealyLightGreen]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_LIGHT_GREEN,
            [styles.galleryCaptionSealyAlizarinCrimson]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_ALIZARIN_CRIMSON,
            [styles.galleryCaptionOrient]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.ORIENT,
            [styles.galleryCaptionRazzmatazz]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.RAZZMATAZZ,
            [styles.galleryCaptionYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.YELLOW,
            [styles.galleryCaptionTeal]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.TEAL,
            [styles.galleryCaptionTextCenter]:
                !!slide.caption_text_alignment &&
                slide.caption_text_alignment === GalleryTextAlignment.CENTER,
            [styles.galleryCaptionTextRight]:
                !!slide.caption_text_alignment &&
                slide.caption_text_alignment === GalleryTextAlignment.RIGHT,
        });

        const roundedCorner = classNames({
            [styles.galleryRoundedCorner]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_LIME_YELLOW,
        });

        return slide.content.map((contentBlock) => {
            if (contentBlock.type === "video_block") {
                return (
                    <RawHTML
                        key={contentBlock.id}
                        className={styles.videoContainer}
                        html={contentBlock.value}
                    />
                );
            }

            if (!contentBlock.value) {
                return null;
            }

            return (
                <figure key={contentBlock.id}>
                    <picture className={roundedCorner}>
                        {contentBlock.value.renditions?.mobile_img && (
                            <source
                                media={`(max-width: ${contentBlock.value.renditions.mobile_img.width}px)`}
                                srcSet={isoImageURL.unwrap(
                                    contentBlock.value.renditions.mobile_img
                                        .url,
                                )}
                            />
                        )}
                        <Image
                            src={contentBlock.value.url}
                            alt={contentBlock.value.title}
                            lazy={getPageSetting("app-slug") !== "cocoon"}
                        />
                        <title>{contentBlock.value.title}</title>
                    </picture>
                    {slide.caption && (
                        <figcaption className={captionClasses}>
                            {slide.caption}
                        </figcaption>
                    )}
                </figure>
            );
        });
    };

    return (
        <span className={styles.root}>
            {isSmallScreenWidth && (
                <div className={styles.slideCount}>
                    {interpolate(t`%(idx)s/%(total)s`, {
                        idx: 1,
                        total: `${props.gallery.gallery.collection.length}`,
                    })}
                </div>
            )}

            {isMediumScreenWidth && (
                <div className={styles.slideButtons}>
                    <button aria-label={t`Previous`}>
                        <SVG aria-hidden="true" src={iconCarouselArrowLeft} />
                    </button>
                </div>
            )}

            {renderSlide(currentSlide.value)}

            {isMediumScreenWidth && (
                <div className={styles.slideButtons}>
                    <button aria-label={t`Next`}>
                        <SVG aria-hidden="true" src={iconCarouselArrowRight} />
                    </button>
                </div>
            )}

            {!isSmallScreenWidth && (
                <nav>
                    <button
                        className={styles.navButton}
                        aria-label={t`Previous`}
                    >
                        <SVG aria-hidden="true" src={iconArrowLeftBlue} />
                    </button>

                    <div className={styles.previews} ref={containerRef}>
                        <div className={styles.inner} ref={innerRef}>
                            {props.gallery.gallery.collection.map(
                                (slide, i) => {
                                    const slideNum = i + 1;
                                    const label = `Slide ${slideNum}`;
                                    const firstContentBlock =
                                        slide.value.content[0];
                                    if (!firstContentBlock.value) {
                                        return null;
                                    }
                                    const thumbnailURL =
                                        firstContentBlock.type === "image"
                                            ? firstContentBlock.value.renditions
                                                  ?.thumbnail?.url
                                            : slide.value.video_thumbnail
                                                  ?.renditions?.thumbnail?.url;
                                    const isCurrentThumbnail = i === 0;

                                    return (
                                        <button
                                            key={i}
                                            className={styles.previewButton}
                                            aria-current={
                                                isCurrentThumbnail
                                                    ? "true"
                                                    : undefined
                                            }
                                        >
                                            <img
                                                src={isoImageURL.unwrap(
                                                    thumbnailURL ||
                                                        props.defaultThumbnail,
                                                )}
                                                alt={label}
                                            />
                                        </button>
                                    );
                                },
                            )}
                        </div>
                    </div>

                    <button className={styles.navButton} aria-label={t`Next`}>
                        <SVG aria-hidden="true" src={iconArrowRightBlue} />
                    </button>
                </nav>
            )}
        </span>
    );
};
