import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";

import { IOptionValues, IProduct } from "../../../models/catalogue.interfaces";
import { IImageURL } from "../../../models/nominals";
import { IViewport } from "../../../models/screen.interfaces";
import { check } from "../../../models/utils";
import { loadWistiaPlayer } from "../../../utils/wistia";
import {
    baseVariantSelector,
    rootProductSelector,
} from "../../configurator/selectors";
import { TStateMapper } from "../../reducers.interfaces";
import {
    GalleryBlock as GalleryBlockCodec,
    IAttributePair,
    IGallery,
    IGalleryBlock,
} from "../models";
import { Gallery } from "./Gallery";
import { GalleryPlaceHolder } from "./GalleryPlaceHolder";

interface IOwnProps {
    galleriesJSON: string;
    defaultThumbnail: IImageURL;
    initialVariantId?: string | null;
}

interface IReduxProps {
    viewport: IViewport;
    selectedRootProduct: IProduct | null;
    optionValues: IOptionValues;
    baseVariant: IProduct | null;
}

type IProps = IOwnProps & IReduxProps;

interface IState {
    gallery: IGallery | null;
    placeHolder: IGallery | null | undefined;
}

class GalleryBlockComponent extends React.PureComponent<IProps, IState> {
    state = {
        gallery: null,
        placeHolder: null,
    };

    private readonly decodeGalleryJSON = memoize((json: string) => {
        return check(GalleryBlockCodec.decode(JSON.parse(json)));
    });

    componentDidMount() {
        loadWistiaPlayer();

        this.loadGallery();
    }

    componentDidUpdate() {
        this.loadGallery();
    }

    attributesInclude(attributes: IOptionValues, attributeVal: IAttributePair) {
        return Object.entries(attributes).find(([c, v]) => {
            return c === attributeVal.code && v === attributeVal.value;
        });
    }

    attributesMatch(
        attributes: IOptionValues,
        galleryAttributes: IAttributePair[],
    ) {
        return galleryAttributes.every((v) =>
            this.attributesInclude(attributes, v),
        );
    }

    private getIdFromCurrentUrl(initialVariantId: number): number {
        const currentUrl = window.location.href;
        const numberAtEnd = currentUrl.match(/\/v\/(\d+)\//);
        // Return the number or "initial variant id"
        return numberAtEnd ? Number(numberAtEnd[1]) : initialVariantId;
    }

    private loadPlaceHolderGallery(block: IGalleryBlock) {
        if (this.props.initialVariantId) {
            const urlVariantId = this.getIdFromCurrentUrl(
                Number(this.props.initialVariantId),
            );
            const placeHolderGallery = block.galleries.find(
                (gallery) => Number(gallery.initial_variant) === urlVariantId,
            );
            this.setState({
                placeHolder: placeHolderGallery,
            });
        }
    }

    private loadGallery() {
        const block = this.decodeGalleryJSON(this.props.galleriesJSON);

        // Set the placeholder gallery
        this.loadPlaceHolderGallery(block);

        // default galleries have no product or attribute set
        const defaultGallery = block.galleries.find((gallery) => {
            if (!gallery.product && !gallery.attributes?.length) {
                return true;
            }
            return false;
        });
        const matchtAttributesGallery = block.galleries.find((gallery) => {
            // only match on galleries with attributes selection and no product selection
            if (
                gallery.product || // don't match if product also selected
                !gallery.attributes?.length ||
                !this.props.selectedRootProduct?.id ||
                !this.props.baseVariant?.id
            ) {
                return false;
            }
            // all selected gallery variants match
            const variantMatch = this.attributesMatch(
                this.props.optionValues,
                gallery.attributes,
            );
            return variantMatch;
        });
        const productMatchGallery = block.galleries.find((gallery) => {
            // only match on product selection but no attributes selection
            if (
                gallery.attributes?.length || // don't match if attributes also selected
                !gallery.product ||
                !this.props.selectedRootProduct?.id ||
                !this.props.baseVariant?.id
            ) {
                return false;
            }
            const isRootOrVariant =
                gallery.product === this.props.selectedRootProduct?.id ||
                gallery.product === this.props.baseVariant?.id;
            return isRootOrVariant;
        });
        const matchProductAndAttributesGallery = block.galleries.find(
            (gallery) => {
                if (
                    !gallery.attributes?.length || // attributes must be selected
                    !gallery.product || // gallery must be selected
                    !this.props.selectedRootProduct?.id ||
                    !this.props.baseVariant?.id
                ) {
                    return false;
                }
                const isRootOrVariant =
                    gallery.product === this.props.selectedRootProduct?.id ||
                    gallery.product === this.props.baseVariant?.id;
                // all selected gallery variants match
                const variantMatch = this.attributesMatch(
                    this.props.optionValues,
                    gallery.attributes,
                );
                return isRootOrVariant && variantMatch;
            },
        );
        if (matchProductAndAttributesGallery) {
            this.setState({
                gallery: matchProductAndAttributesGallery,
            });
        } else if (productMatchGallery) {
            this.setState({
                gallery: productMatchGallery,
            });
        } else if (matchtAttributesGallery) {
            this.setState({
                gallery: matchtAttributesGallery,
            });
        } else if (defaultGallery) {
            this.setState({
                gallery: defaultGallery,
            });
            // reset to no gallery
        } else {
            this.setState({
                gallery: null,
            });
        }
    }

    render() {
        if (!this.state.gallery) {
            // If gallery is not available, render GalleryPlaceHolder if placeHolder exists
            if (!this.state.placeHolder) {
                return null;
            }
            return (
                <GalleryPlaceHolder
                    gallery={this.state.placeHolder}
                    defaultThumbnail={this.props.defaultThumbnail}
                    viewport={this.props.viewport}
                />
            );
        }
        return (
            <Gallery
                gallery={this.state.gallery}
                defaultThumbnail={this.props.defaultThumbnail}
                viewport={this.props.viewport}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        viewport: state.common.viewport,
        optionValues: state.configurator.ui.optionValues,
        selectedRootProduct: rootProductSelector(state.configurator),
        baseVariant: baseVariantSelector(state.configurator),
        ...ownProps,
    };
};

export const GalleryBlock = connect(mapStateToProps)(GalleryBlockComponent);
